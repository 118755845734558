import { createGlobalStyle } from "styled-components";
import { theme } from "ui";

export const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    ::-moz-selection { /* Code for Firefox */
      color: ${theme.colors.neutral.white};
      background: ${theme.colors.primary["200"]};

    }

    ::selection {
      color: ${theme.colors.neutral.white};
      background: ${theme.colors.primary["200"]};

    }
  }

  html, body {
    font-family: 'Chivo', Arial, Helvetica, sans-serif;
    font-size: ${theme.typography.fontSize.sm.x3};
    font-weight: 500;
    font-style: normal;
    margin: 0 auto;
    width: auto;
    -webkit-font-smoothing: antialiased;

    & * {
      font-family: 'Chivo', Arial, Helvetica, sans-serif;
    }
  }
  
  :focus {
    outline: none;
  }

  a, a:link, button {
    -webkit-tap-highlight-color: rgba(218, 8, 18, 0.2);
  }

  a, a:link {
    text-decoration: none;
    font-family: 'Chivo', sans-serif;
    outline: 0;
  }

  .carousel .control-dots .dot {
    border-radius: ${theme.space["x1/4"]} !important;
    width: ${theme.space.x5};
    height: ${theme.space.x1};
    /* background: ${theme.colors.neutral["200"]}; */
    background: rgba(189,189,189,0.5);
    box-shadow: none;
  }

  .carousel .control-dots .selected {
    width: ${theme.space.x6};
    background: ${theme.colors.primary["200"]};
  }

  path, rect, circle {
    transition: stroke .2s ease-in;
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
    overflow: hidden;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }
`;
