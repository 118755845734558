import {
  isFebruaryInvalid,
  quantityOfDaysIsBiggerThanThirty,
} from "../birthdate";

interface DateValidationResult {
  hasError: boolean;
  errorMessage: string;
}

export const isLeapYear = (year: number) => {
  return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
};

export const sumYears = (date: Date, quantity: number) => {
  let year = date.getFullYear();
  let yearsInMillisecondsToSum = 0;

  for (let i = 0; i < quantity; i += 1) {
    if (isLeapYear(year)) {
      yearsInMillisecondsToSum += 1000 * 60 * 60 * 24 * 366;
    } else {
      yearsInMillisecondsToSum += 1000 * 60 * 60 * 24 * 365;
    }

    year += 1;
  }

  return new Date(date.getTime() + yearsInMillisecondsToSum);
};

export const validateWeddingDate = (
  dateString: string
): DateValidationResult => {
  const dateRegex = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
  const matches = dateString?.match(dateRegex);
  if (!matches) {
    return {
      hasError: true,
      errorMessage: "Data no formato inválido!",
    };
  }

  const day = parseInt(matches[1], 10);
  const month = parseInt(matches[2], 10);
  const year = parseInt(matches[3], 10);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();

  if (
    month < 1 ||
    month > 12 ||
    day < 1 ||
    isFebruaryInvalid(day, month, year) ||
    quantityOfDaysIsBiggerThanThirty(day, month)
  ) {
    return {
      hasError: true,
      errorMessage: "Data no formato inválido!",
    };
  }

  if (
    year < currentYear ||
    (year === currentYear && month < currentMonth) ||
    (year === currentYear && month === currentMonth && day < currentDay)
  ) {
    const formattedCurrentDate = `${currentDay
      .toString()
      .padStart(2, "0")}/${currentMonth
      .toString()
      .padStart(2, "0")}/${currentYear}`;
    return {
      hasError: true,
      errorMessage: `A data do evento deve ser maior que a data atual ${formattedCurrentDate}`,
    };
  }

  const maxDate = sumYears(new Date(), 1);

  if (
    year > maxDate.getFullYear() ||
    (year === maxDate.getFullYear() && new Date(year, month - 1, day) > maxDate)
  ) {
    const formattedMaxDate = `${maxDate
      .getDate()
      .toString()
      .padStart(2, "0")}/${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}/${maxDate.getFullYear()}`;
    return {
      hasError: true,
      errorMessage: `A data do evento deve ser menor que ${formattedMaxDate}`,
    };
  }

  return {
    hasError: false,
    errorMessage: "",
  };
};
