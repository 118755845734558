// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import { anonymizeUserData, dropListTerms } from "application";
import packageJson from "./package.json";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const hostEnv = `${process.env.NEXT_PUBLIC_HOST_ENV}`;

Sentry.init({
  environment: hostEnv || "production",
  dsn:
    SENTRY_DSN ||
    "https://1ab82a08b42f46a398992b7cccdcdd60@o4504957609836544.ingest.sentry.io/4505071174025216",
  enabled: hostEnv !== "qas",
  // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,
  sampleRate: 0.1,
  debug: hostEnv === "development",
  // spotlight: hostEnv === "development",

  beforeSend(event) {
    const { request, breadcrumbs } = event;
    const user = event?.user || {};
    const data = request?.data || {};
    const cookies = request?.cookies || {};
    const headers = request?.headers || {};

    const {
      anonymizedData,
      anonymizedUserData,
      anonymizedCookies,
      anonymizedHeaders,
    } = anonymizeUserData(data, user, cookies, headers);

    breadcrumbs?.forEach((breadcrumb) => {
      if (breadcrumb?.message?.includes("can reduce performance")) {
        breadcrumbs.splice(breadcrumbs.indexOf(breadcrumb), 1);
      }
    });

    return {
      ...event,
      request: {
        ...request,
        data: anonymizedData,
        cookies: anonymizedCookies,
        headers: anonymizedHeaders,
      },
      user: anonymizedUserData,
    };
  },

  beforeSendTransaction(transaction) {
    const { request } = transaction;
    const user = transaction?.user || {};
    const data = request?.data || {};
    const cookies = request?.cookies || {};
    const headers = request?.headers || {};

    const {
      anonymizedData,
      anonymizedUserData,
      anonymizedCookies,
      anonymizedHeaders,
    } = anonymizeUserData(data, user, cookies, headers);

    return {
      ...transaction,
      request: {
        ...request,
        data: anonymizedData,
        cookies: anonymizedCookies,
        headers: anonymizedHeaders,
      },
      user: anonymizedUserData,
    };
  },

  beforeBreadcrumb(breadcrumb) {
    if (
      breadcrumb?.message?.includes("can reduce performance") ||
      // eslint-disable-next-line dot-notation
      breadcrumb?.data["__span"]
    ) {
      return null;
    }
    return breadcrumb;
  },
  release: packageJson.version,
  sendDefaultPii: true,
  ignoreErrors: dropListTerms,
  tracesSampler: ({ name, parentSampled }) => {
    if (name?.includes("healthcheck")) {
      return 0.2;
    }

    if (name?.includes("auth")) {
      return 1;
    }

    // These are less important or happen much more frequently - only take 1%
    if (name?.includes("comment")) {
      return 0.01;
    }

    // Continue trace decision, if there is any parentSampled information
    if (typeof parentSampled === "boolean") {
      return parentSampled;
    }

    // eslint-disable-next-line array-callback-return, consistent-return
    dropListTerms.map((term) => {
      if (name?.includes(term)) {
        return false;
      }
    });

    return 0.3;
  },
});
