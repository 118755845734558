import { useAuth } from "application";
import { AccountBaseModalProps } from "components";
import { weddingGiftListApi } from "implementations";
import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  CoupleDetailDto,
  CoupleNamesDto,
  EnvironmentAddDto,
  EnvironmentDto,
  EnvironmentsDto,
  GiftsListProduct,
  IdByListLinkDto,
  IHttpResponse,
  WeddingListDtoFull,
} from "typing";
import { theme } from "ui";
import { getCookieValue } from "../MyPanel/HookWeddingGiftList";

const createNewAmbientStr = "Criar novo ambiente";

export type FilterGiftsParams = {
  selectedFilterCategories: string[];
  selectedFilterBrands: string[];
  selectedFilterModels: string[];
};

const removeItemsStr = "Remover itens";

interface CouplesListFilterType {
  name: string;
  parentNames: string;
  startDate: string;
  endDate: string;
  pageIndex: number;
  pageSize: number;
  status: string;
}
export interface WeddingGiftListState {
  couplesList: IHttpResponse<CoupleDetailDto[], unknown>;
  setParams: Dispatch<SetStateAction<FilterGiftsParams>>;
  setCouplesList: Dispatch<
    SetStateAction<IHttpResponse<CoupleDetailDto[], unknown>>
  >;
  coupleListFilter: CouplesListFilterType;
  setCoupleListFilter: Dispatch<CouplesListFilterType>;
  setSearchListId: Dispatch<SetStateAction<string>>;
  searchListId: string;
  modalConfig: Pick<
    AccountBaseModalProps,
    | "contentLabel"
    | "title"
    | "buttonText"
    | "buttonTextUnderAction"
    | "height"
    | "alternativeButtonText"
    | "modalButtonColor"
    | "buttonHoverColor"
  >;

  setModalConfig: (
    value: SetStateAction<
      Pick<
        AccountBaseModalProps,
        | "contentLabel"
        | "title"
        | "buttonText"
        | "buttonTextUnderAction"
        | "height"
        | "alternativeButtonText"
        | "modalButtonColor"
        | "buttonHoverColor"
      >
    >
  ) => void;

  openModalAdd: () => void;
  openModalEdit: (props: EnvironmentDto) => void;
  openModalDelete: (props: EnvironmentDto) => void;
  openModalTransfer: () => void;
  openModalDeleteProducts: () => void;
  environmentModalOpened: boolean;
  isModalEdit: boolean;
  isModalTransfer: boolean;
  environmentToRename: EnvironmentAddDto | null | undefined;
  setEnvironmentModalOpened: Dispatch<SetStateAction<boolean>>;
  userLists: WeddingListDtoFull[] | null | undefined;
  isUserListsLoading: boolean | undefined;
  activeUser: CoupleNamesDto | null | undefined;
  initialEnvironmentsList: EnvironmentsDto | null | undefined;
  isInitialLoadingEnvironments: boolean;
  environmentsFilteredList: EnvironmentsDto | null | undefined;
  isLoadingFilteredEnvironments: boolean;
  triggerEnvironmentsFn: () => void;
  userListsError: unknown;
  triggerFilteredEnvironmentsFn: () => void;
  isModalDelete: boolean;
  isDeleteProducts: boolean;
  environmentToDelete: number | null | undefined;
  isProductDetailModalOpen: boolean;
  setIsProductDetailModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedProductDetail: GiftsListProduct | undefined;
  setSelectedProductDetail: Dispatch<
    SetStateAction<GiftsListProduct | undefined>
  >;
  selectedProductsList: GiftsListProduct[];
  setSelectedProductsList: Dispatch<SetStateAction<GiftsListProduct[]>>;
  setIsInvalid: Dispatch<SetStateAction<boolean>>;
  isInvalid: boolean;
  isMyList: boolean;
}

const WeddingGiftListContext = createContext<WeddingGiftListState>(
  {} as WeddingGiftListState
);

const useSharedState = ({
  ...props
}: WeddingGiftListState): WeddingGiftListState => {
  return {
    ...props,
  };
};

export const WeddingGiftListWrapper = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token;
  const [searchListId, setSearchListId] = useState("");
  const [isMyList, setIsMyList] = useState<boolean>(false);
  const [couplesList, setCouplesList] = useState({
    data: [],
    pagination: null,
    error: null,
    isLoading: false,
  } as IHttpResponse<CoupleDetailDto[], unknown>);
  const [coupleListFilter, setCoupleListFilter] =
    useState<CouplesListFilterType>({
      name: "",
      parentNames: "",
      startDate: "",
      endDate: "",
      pageIndex: 1,
      pageSize: 8,
      status: "Open",
    });
  const [environmentModalOpened, setEnvironmentModalOpened] =
    useState<boolean>(false);
  const [isModalEdit, setIsModalEdit] = useState<boolean>(false);
  const [isModalTransfer, setIsModalTransfer] = useState<boolean>(false);
  const [environmentToRename, setEnvironmentToRename] =
    useState<EnvironmentAddDto>();
  const [environmentToDelete, setEnvironmentToDelete] = useState<number>();
  const [isModalDelete, setIsModalDelete] = useState<boolean>(false);
  const [isDeleteProducts, setIsDeleteProducts] = useState<boolean>(false);
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] =
    useState<boolean>(false);
  const [selectedProductDetail, setSelectedProductDetail] = useState<
    GiftsListProduct | undefined
  >();
  const [selectedProductsList, setSelectedProductsList] = useState<
    GiftsListProduct[]
  >([]);
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const router = useRouter();
  const { id, listLink } = router.query;

  const [params, setParams] = useState<FilterGiftsParams>({
    selectedFilterCategories: [],
    selectedFilterBrands: [],
    selectedFilterModels: [],
  });

  const { data: weddingListLinkInfo } = weddingGiftListApi.getIdByListLink(
    listLink as string,
    !!listLink
  ) as IHttpResponse<IdByListLinkDto, unknown>;

  useEffect(() => {
    if (listLink || id) {
      if (weddingListLinkInfo?.status === 404 && !id) {
        router.replace("/404");
      }
      setSearchListId(String(listLink ? weddingListLinkInfo?.id : id));
    }
  }, [setSearchListId, id, listLink, weddingListLinkInfo, router]);

  const userList = getCookieValue("@FC:Ecom:Token:userList");

  const { data: activeUser, isLoading: isLoadingGetActiveUser } =
    weddingGiftListApi.getWeddingList(
      accessToken as unknown as string,
      userList as unknown as number,
      !!accessToken
    ) as unknown as IHttpResponse<CoupleNamesDto, unknown>;

  const {
    data: userLists,
    isLoading: isUserListsLoading,
    error: userListsError,
  } = weddingGiftListApi.getWeddingLists(
    getTokens()?.token ?? "",
    Boolean(getTokens()?.token)
  );

  const {
    data: initialEnvironmentsList,
    isLoading: isInitialLoadingEnvironments,
    trigger: triggerEnvironments,
  } = weddingGiftListApi.getEnvironments(
    Number(searchListId) ? Number(searchListId) : activeUser?.id,
    !!((!isLoadingGetActiveUser && activeUser?.id) || Number(searchListId))
  ) as unknown as IHttpResponse<EnvironmentsDto, unknown>;

  const triggerEnvironmentsFn = () => {
    if (triggerEnvironments) {
      triggerEnvironments();
    }
  };

  const {
    data: environmentsFilteredList,
    isLoading: isLoadingFilteredEnvironments,
    trigger: triggerFilteredEnvironments,
  } = weddingGiftListApi.getFilteredGifts(
    // getTokens()?.token as unknown as string,
    Number(searchListId) ? Number(searchListId) : activeUser?.id,
    params.selectedFilterCategories.length
      ? params.selectedFilterCategories.join(";")
      : "",
    params.selectedFilterBrands.length
      ? params.selectedFilterBrands.join(";")
      : "",
    params.selectedFilterModels.length
      ? params.selectedFilterModels.join(";")
      : "",
    !!(
      (!isLoadingGetActiveUser &&
        activeUser?.id &&
        (params.selectedFilterCategories ||
          params.selectedFilterBrands ||
          params.selectedFilterModels)) ||
      Number(searchListId)
    )
  ) as unknown as IHttpResponse<EnvironmentsDto, unknown>;
  useEffect(() => {
    // setIsMyList(
    //   !!(!Number(searchListId) || activeUser?.id === Number(searchListId))
    // );

    setIsMyList(!Number(searchListId));
  }, [setIsMyList, searchListId, activeUser?.id]);

  const triggerFilteredEnvironmentsFn = () => {
    if (triggerFilteredEnvironments) {
      triggerFilteredEnvironments();
    }
  };

  const [modalConfig, setModalConfig] = useState<
    Pick<
      AccountBaseModalProps,
      | "contentLabel"
      | "title"
      | "buttonText"
      | "buttonTextUnderAction"
      | "height"
      | "alternativeButtonText"
      | "modalButtonColor"
      | "buttonHoverColor"
    >
  >({
    contentLabel: createNewAmbientStr,
    title: createNewAmbientStr,
    buttonText: "Criar",
    buttonTextUnderAction: "Adicionando ambiente...",
    height: "50%",
  });

  const openModalAdd = () => {
    setIsModalEdit(false);
    setIsModalDelete(false);
    setIsModalTransfer(false);
    setIsDeleteProducts(false);

    setModalConfig({
      contentLabel: createNewAmbientStr,
      title: createNewAmbientStr,
      buttonText: "Criar",
      buttonTextUnderAction: "Adicionando ambiente...",
      height: "50%",
    });

    setEnvironmentModalOpened(true);
  };

  const openModalEdit = (props: EnvironmentDto) => {
    setIsModalEdit(true);
    setIsModalDelete(false);
    setIsModalTransfer(false);
    setIsDeleteProducts(false);

    setEnvironmentToRename({
      id: props.id,
      name: props.name,
    });

    setModalConfig({
      contentLabel: "Renomear ambiente",
      title: "Renomear ambiente",
      buttonText: "Renomear",
      buttonTextUnderAction: "Renomeando ambiente...",
      height: "30%",
    });

    setEnvironmentModalOpened(true);
  };

  const openModalDelete = (props: EnvironmentDto) => {
    setIsModalDelete(true);
    setIsModalEdit(false);
    setIsModalTransfer(false);
    setIsDeleteProducts(false);

    setEnvironmentToDelete(props.id);

    setModalConfig({
      contentLabel: "Excluir ambiente e itens",
      title: "Excluir ambiente",
      buttonText: "Excluir ambiente e continuar",
      buttonTextUnderAction: "Excluindo ambiente...",
      height: "30%",
      alternativeButtonText: "Não, cancelar",
      modalButtonColor: theme.colors.primary["200"],
      buttonHoverColor: theme.colors.primary["330"],
    });

    setEnvironmentModalOpened(true);
    setIsInvalid(false);
  };

  const openModalDeleteProducts = () => {
    setIsModalDelete(true);
    setIsDeleteProducts(true);
    setIsModalEdit(false);
    setIsModalTransfer(false);

    setModalConfig({
      contentLabel: removeItemsStr,
      title: removeItemsStr,
      buttonText: "Remover",
      buttonTextUnderAction: "Removendo produtos...",
      height: "30%",
      alternativeButtonText: "Cancelar",
      modalButtonColor: theme.colors.primary["200"],
      buttonHoverColor: theme.colors.primary["330"],
    });

    setEnvironmentModalOpened(true);
  };

  const openModalTransfer = () => {
    setIsModalTransfer(true);
    setIsModalEdit(false);
    setIsModalDelete(false);

    setModalConfig({
      contentLabel: "Transferir para Ambiente",
      title: "Transferir para Ambiente",
      buttonText: "Transferir",
      buttonTextUnderAction: "Transferindo...",
      height: "30%",
    });

    setEnvironmentModalOpened(true);
  };

  return (
    <WeddingGiftListContext.Provider
      value={useSharedState({
        couplesList,
        setCouplesList,
        coupleListFilter,
        setCoupleListFilter,
        setParams,
        setSearchListId,
        searchListId,
        modalConfig,
        setModalConfig,
        openModalAdd,
        openModalEdit,
        openModalDelete,
        openModalTransfer,
        openModalDeleteProducts,
        environmentModalOpened,
        setEnvironmentModalOpened,
        isModalEdit,
        isModalTransfer,
        environmentToRename,
        userLists,
        activeUser,
        initialEnvironmentsList,
        isInitialLoadingEnvironments,
        environmentsFilteredList,
        isLoadingFilteredEnvironments,
        triggerEnvironmentsFn,
        triggerFilteredEnvironmentsFn,
        isModalDelete,
        isDeleteProducts,
        environmentToDelete,
        isProductDetailModalOpen,
        setIsProductDetailModalOpen,
        selectedProductDetail,
        setSelectedProductDetail,
        selectedProductsList,
        setSelectedProductsList,
        setIsInvalid,
        isInvalid,
        isMyList,
        isUserListsLoading,
        userListsError,
      })}
    >
      {children}
    </WeddingGiftListContext.Provider>
  );
};

export const useWeddingGiftListContext = () => {
  return useContext(WeddingGiftListContext);
};
