import {
  BroadcastChannelProvider,
  CartProvider,
  CountdownProviderProps,
  EventListenerProviderProps,
  HeaderProvider,
  ModalGroupProviderProps,
  NotificationProvider,
  RecaptchaContextProviderProps,
  WebSocketContextProviderProps,
  obfuscate,
  useInitializeAllConfigs,
} from "application";
import { BranchLocationProvider } from "application/context/BranchLocationContext";
import { CookiesProvider } from "application/context/CookiesContext";
import { AuthProvider, UserProvider } from "application/context/Identity/";
import { PurchaseProviderProps } from "application/context/Purchase/PurchaseContext";
import { AppWrapper } from "application/context/state";
import { Layout } from "components/patterns";
import {
  allowMeFingerprint,
  appMonitoringClient,
  backofficeApi,
  browserBroadcast,
  catalogApi,
  clearSaleFingerprint,
  clientIp,
  cookie,
  crm,
  envsApi,
  eventEmitter,
  gtag,
  heatmapClient,
  identityApi,
  jwtDecoder,
  notificationApi,
  paymentApi,
  profileApi,
  purchaseApi,
  shippingApi,
  shoppingApi,
  stateManager,
  webSocketClient,
} from "implementations";
import { NextPage } from "next";
import { Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Script from "next/script";
import { Fragment, ReactElement, ReactNode, useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import { ModalAlertProps, ToastProviderProps } from "typing";
import { theme } from "ui";
import { WeddingGiftListWrapper } from "../src/screens/context/state";
import { GlobalStyles } from "../src/styles";
import "../src/styles/fonts.css";
import "../whyDidYouRender";

const ToastProvider = dynamic<ToastProviderProps>(() =>
  import("application/hooks/Toast").then((module) => module.ToastProvider)
);

const PopUpAlertConfirm = dynamic<ModalAlertProps>(() =>
  import("components/PopUpAlertConfirm").then(
    (module) => module.PopUpAlertConfirm
  )
);

const PurchaseProvider = dynamic<PurchaseProviderProps>(() =>
  import("application/context/Purchase/PurchaseContext").then(
    (module) => module.PurchaseProvider
  )
);

const CookiesModal = dynamic<Record<string, never>>(() =>
  import("components/CookiesModal").then((module) => module.CookiesModal)
);

const CountdownProvider = dynamic<CountdownProviderProps>(() =>
  import("application/hooks/Countdown").then(
    (module) => module.CountdownProvider
  )
);

const EventListenerProvider = dynamic<EventListenerProviderProps>(() =>
  import("application/context/EventListener").then(
    (module) => module.EventListenerProvider
  )
);

const ModalGroupProvider = dynamic<ModalGroupProviderProps>(() =>
  import("application/context/ModalGroupContext").then(
    (module) => module.ModalGroupProvider
  )
);

const RecaptchaContextProvider = dynamic<RecaptchaContextProviderProps>(() =>
  import("application/context/Recaptcha").then(
    (module) => module.RecaptchaContextProvider
  )
);

const WebSocketContextProvider = dynamic<WebSocketContextProviderProps>(() =>
  import("application/context/WebSocket").then(
    (module) => module.WebSocketContextProvider
  )
);

export type NextPageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const MyApp = ({
  Component,
  pageProps: { ...pageProps },
}: AppPropsWithLayout) => {
  const [showChild, setShowChild] = useState(false);

  const hostEnv = process.env.NEXT_PUBLIC_HOST_ENV;

  useEffect(() => {
    setShowChild(true);
  }, []);

  useInitializeAllConfigs(envsApi);

  if (!showChild) {
    return null;
  }

  if (typeof window === "undefined") {
    return <Fragment key="fragment-wedding-gift-list" />;
  }

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://fcx-s3-fercos-backoffice.ferreiracosta.com/scripts/google-tag-manager/gtag.js?id=${obfuscate(
          gtag.GA_TRACKING_ID || ""
        )}`}
      />

      <Script id="google-tag-manager" strategy="lazyOnload">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${obfuscate(
          gtag.GTM_ID || ""
        )}');
      `}
      </Script>

      <Script
        id="google-tag-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${obfuscate(
              gtag.GA_TRACKING_ID || ""
            )}', { 'debug_mode': ${
            hostEnv !== "production" && hostEnv !== "qas"
          } });

            gtag('config', '${obfuscate(gtag.GTM_ID || "")}', { 'debug_mode': ${
            hostEnv !== "production" && hostEnv !== "qas"
          } });

          ${
            hostEnv !== "production" &&
            hostEnv !== "qas" &&
            `
            gtag('config', '${obfuscate(
              gtag.GA_TRACKING_ID_DEV || ""
            )}', { 'debug_mode': true });`
          }
          `,
        }}
      />

      {/*  Não dá para cachearmos o script da insider, a url atual se mantém atualizada automaticamente por eles */}
      <Script
        src={`https://ferreiracosta.api.useinsider.com/ins.js?id=${obfuscate(
          crm.insiderId || ""
        )}`}
        strategy="lazyOnload"
      />

      {heatmapClient.Script ? <heatmapClient.Script /> : null}

      <GlobalStyles />

      <ThemeProvider theme={theme}>
        <CountdownProvider>
          <ToastProvider>
            <RecaptchaContextProvider>
              <SessionProvider
                session={(pageProps as { session: Session }).session}
              >
                <ModalGroupProvider cookie={cookie} identityApi={identityApi}>
                  <WebSocketContextProvider
                    webSocketClient={webSocketClient}
                    appMonitoringClient={appMonitoringClient}
                    eventEmitter={eventEmitter}
                  >
                    <BroadcastChannelProvider
                      broadcastChannelClient={browserBroadcast}
                    >
                      <AuthProvider
                        identityApi={identityApi}
                        appMonitoringClient={appMonitoringClient}
                        cookie={cookie}
                        jwtDecoder={jwtDecoder}
                        heatmapClient={heatmapClient}
                        purchaseApi={purchaseApi}
                        profileApi={profileApi}
                      >
                        <UserProvider
                          profileApi={profileApi}
                          crm={crm}
                          cookie={cookie}
                          appMonitoringClient={appMonitoringClient}
                        >
                          <AppWrapper
                            catalogApi={catalogApi}
                            stateManager={stateManager}
                          >
                            <BranchLocationProvider
                              cookie={cookie}
                              appMonitoringClient={appMonitoringClient}
                              shippingApi={shippingApi}
                            >
                              <CookiesProvider
                                heatmapClient={heatmapClient}
                                cookie={cookie}
                              >
                                <CartProvider
                                  cookie={cookie}
                                  shoppingApi={shoppingApi}
                                  catalogApi={catalogApi}
                                  PopUpAlertConfirm={PopUpAlertConfirm}
                                  appMonitoringClient={appMonitoringClient}
                                >
                                  <PurchaseProvider
                                    purchaseApi={purchaseApi}
                                    paymentApi={paymentApi}
                                    shippingApi={shippingApi}
                                    allowMeFingerprint={allowMeFingerprint}
                                    clearSaleFingerprint={clearSaleFingerprint}
                                    appMonitoringClient={appMonitoringClient}
                                    clientIp={clientIp}
                                  >
                                    <NotificationProvider
                                      notificationApi={notificationApi}
                                    >
                                      <HeaderProvider
                                        backofficeApi={backofficeApi}
                                        cookie={cookie}
                                      >
                                        <EventListenerProvider cookie={cookie}>
                                          {Component.getLayout ? (
                                            Component.getLayout(
                                              <>
                                                <CookiesModal />
                                                <WeddingGiftListWrapper>
                                                  <Component {...pageProps} />
                                                </WeddingGiftListWrapper>
                                              </>
                                            )
                                          ) : (
                                            <Layout>
                                              <CookiesModal />
                                              <WeddingGiftListWrapper>
                                                <Component {...pageProps} />
                                              </WeddingGiftListWrapper>
                                            </Layout>
                                          )}
                                        </EventListenerProvider>
                                      </HeaderProvider>
                                    </NotificationProvider>
                                  </PurchaseProvider>
                                </CartProvider>
                              </CookiesProvider>
                            </BranchLocationProvider>
                          </AppWrapper>
                        </UserProvider>
                      </AuthProvider>
                    </BroadcastChannelProvider>
                  </WebSocketContextProvider>
                </ModalGroupProvider>
              </SessionProvider>
            </RecaptchaContextProvider>
          </ToastProvider>
        </CountdownProvider>
      </ThemeProvider>
    </>
  );
};

export default MyApp;
